<template>
  <div id="app">
    <!-- White Taskbar -->
    <div class="top-taskbar">
      <!-- Centered Logo with Tagline -->
      <div class="logo-container">
        <div @click="handleAdvertising" class="logo-header-container">
          <img
            src="../../assets/logo-with-text.png"
            alt="Logo"
            style=""
            class="top-taskbar-logo"
          />
          <div v-if="!this.mobileScreen" class="divider"></div>
          <div class="tagline-for-header">
            {{ translations.networkmainheader }}            
            <div class="tooltip-container">
              <font-awesome-icon
                :icon="['fas', 'info-circle']"
                style="color: #000; cursor: pointer; font-size: 14px; margin-left: 5px; margin-bottom: 15px;"
              />
              <span class="tooltip-text">
                Mindspeller is a revolutionary, human-powered search engine that
                responds to your thoughts. By wearing an EEG headset, you can
                train it to generate results effortlessly—no typing required.
                Sign in to reclaim control of your data, explore your
                subconscious, and gain deeper insights into your mental
                well-being
              </span>
            </div>
          </div>
        </div>
        <div class="tagline">
          {{ translations.networkheader1 }}<a href="#" class="underline-link blink-text" @click.prevent="launchBrandGameAgain">{{ translations.networkheader2 }}</a>
        </div>
      </div>
      <!-- User Icon on the top right -->
      <div class="p-2 align" >
        <div class="reward-icon-wrapper" @click="openBrandGamePopup">
          <span
            v-if="this.api_call_count >= 2 && !this.mobileScreen"
            class="play-button"
            @click="openBrandGamePopup"
            >{{ translations.earn_rewards }}</span
          >
          <span
            v-else-if="this.mobileScreen"
            class="play-button"
            @click="openBrandGamePopup"
            >{{ translations.earn_rewards }}</span
          >
        </div>

        <div class="cta-icon-wrapper" @click="launchMindSearchPopup">
          <span class="order-button">{{ translations.order_headset }}</span>
        </div>
        <div
          class="user-login-icon-wrapper"
          @click="openWordPopup"
        >
          <img
            src="../../assets/ctamindspellersearchicon.png"
            alt="Step 1 Image"
            class="cta-user-icon"
          />
          <span class="login-text">Login</span>
        </div>
      </div>
      <!-- <div class="brand-icon-wrapper" @click="openBrandGamePopup">
        <font-awesome-icon :icon="['fas', 'table']" alt="Login" class="login-user-icon" />
        <span class="login-text">Brand Game</span>
      </div> -->
    </div>
    <div v-if="mobileScreen" class="mobile-container">
      <header>
        <div class="leftbar-title">
          {{translations.my_search}}
        </div>
        <word-picker-mobile
          @dictWordSelected="onMobileWordSelected"
          @wordRemoved="onMobileWordRemoved"
          @nosearchresults="joinCommunity"
        />
        <div v-if="showJoinCommunity" class="leftbar-title">
          {{translations.cant_find}}
          <a
            href="https://www.mindspeller.com/#/respondent_signup"
            target="_blank"
            >{{translations.join_community}}</a
          >
        </div>
      </header>
    <Sidebar v-if="wordData && Object.keys(wordData).length && mobileScreen" style="height: 30% !important; margin-bottom: 20%;"
        :wordData="wordData"
        :usageNetworkLimit="usageLimit"
        :mobileScreen="mobileScreen"
        :api_call_count="api_call_count"
      @update:api_call_count="api_call_count = $event"
      />
    </div>
    <!-- Custom Sidebar for displaying the node information -->
    <div
      class="b2c-sidebar-wrapper"
      v-if="wordData && Object.keys(wordData).length && !mobileScreen"
    >
      <Sidebar
        :wordData="wordData"
        :usageNetworkLimit="usageLimit"
        :mobileScreen="mobileScreen"
        :api_call_count="api_call_count"
      @update:api_call_count="api_call_count = $event"
      />
    </div>
    <!-- Grouped Instructions Button and Pointing Label -->
    <div v-if="!this.mobileScreen" class="b2c-instructions-wrapper">
      <button class="instructions-button" @click="openInstructionsPopup">
        <font-awesome-icon :icon="['fas', 'question']" class="header-icon" />
      </button>
      <div v-if="this.showInstructionPopup" class="readme-label">
        {{ translations.read_me }}
      </div>
    </div>

    <!-- Instructions Popup -->
    <InstructionsPopup ref="instructionsPopup" />
    <WelcomePopup ref="welcomePopup" />
    <B2CLoginPopup ref="b2cLoginPopup" />
    <SearchLimitPopup ref="searchLimitPopup" />
    <B2CSignupPopup ref="b2cSignupPopup" />
    <MindSearchPopup @close="closeMindSearchPopup" v-if="showMindSearchPopup" />
    <BrandGamePopup
      ref="brandGamePopup"
      :surveyStatus="this.surveyStatus"
      :brandGameStatus="this.brandGameStatus"
      @start-game="launchBrandGame"
      @share-thoughts="launchShareThoughts"
    />
    <BrandGame
      v-if="showBrandGame"
      ref="brandGame"
      @close="closeBrandGame"
      @brandGameOver="brandGameOverStatus"
      :showBrandGame="this.showBrandGame"
    />

    <BrandGameAgain
      v-if="showBrandGameAgain"
      ref="brandGameAgain"
      @close="closeBrandGameAgain"
      :showBrandGame="this.showBrandGameAgain"
    />

    <ShareThoughtsPopup
      v-if="showSurveyPopup"
      ref="shareThoughts"
      @close="closeShareThoughts"
      @survey-submitted="surveySubmitted"
      :showSurveyPopup="this.showSurveyPopup"
    />

    <!-- TensorBoard iframe embedded -->
    <div class="iframe-wrapper">
      <iframe v-if="!this.mobileScreen" :src="iframeSrc"></iframe>
    </div>

    <!-- Bottom Taskbar -->
    <!-- <footer class="bottom-taskbar">
      <div class="bottom-taskbar-center">
        Region:
        <select
          style="text-align: center;"
          v-model="currentlang"
          @change="updateIframeSrcBasedOnLang"
        >
          <option value="en">USA (EN)</option>
          <option value="nl">Europe (NL)</option>
        </select>
      </div>
      <div class="bottom-taskbar-left">
        <img
          src="../../assets/mindspellerAds.png"
          @click="handleAdvertising"
          alt="Step 1 Image"
          style="max-width: 165px; max-height: 100px; margin: auto; cursor: pointer;"
        />
      </div>
      <div class="bottom-taskbar-center">
        <a
          href="https://www.mindspeller.com/#/respondent_signup"
          target="_blank"
          rel="noopener noreferrer"
          >Join Our Community
        </a>
        of over <CountingNumber :number="totalMembers" /> Minds Mapping Brand
        Recall
      </div>
      <div class="bottom-taskbar-right">
        <button @click="handleLegal" class="bottom-button">Legal</button>
      </div>
    </footer> -->
    <!-- <footer class="bottom-taskbar-region">
      <div class="bottom-taskbar-center">
        Region:
        <select
          style="text-align: center;"
          v-model="currentlang"
          @change="updateIframeSrcBasedOnLang"
        >
          <option value="en">USA (EN)</option>
          <option value="nl">Europe (NL)</option>
        </select>
      </div>
      <div class="bottom-taskbar">
        <div class="bottom-taskbar-left">
        <img
          src="../../assets/mindspellerAds.png"
          @click="handleAdvertising"
          alt="Step 1 Image"
          style="max-width: 165px; max-height: 100px; margin: auto; cursor: pointer;"
        />
      </div>
      <div class="bottom-taskbar-center">
        <a
          href="https://www.mindspeller.com/#/respondent_signup"
          target="_blank"
          rel="noopener noreferrer"
          >Join Our Community
        </a>
        of over <CountingNumber :number="totalMembers" /> Minds Mapping Brand
        Recall
      </div>
      <div class="bottom-taskbar-right">
        <button @click="handleLegal" class="bottom-button">Legal</button>
      </div>
      </div>
    </footer> -->


    <footer class="p-2 footer">
      <div class="bottom-taskbar-center">{{ translations.region }}
        <select v-model="currentlang" @change="updateRegionOverride" style="text-align: center;">
          <option value="en">USA (EN)</option>
          <option value="nl">Europe (NL)</option>
        </select>
      </div>
      <div class="footer-inner">
        <div >
          <img
            src="../../assets/mindspellerAds.png"
            @click="handleAdvertising"
            alt="Step 1 Image"
            style="max-width: 165px; max-height: 100px; margin: auto; cursor: pointer;"
          />
        </div>
        <div >
          <a
            href="#"
            @click.prevent="openWordPopup"
            target="_blank"
            rel="noopener noreferrer"
            >Join Our Community
          </a>
          <span>
            of over <CountingNumber :number="totalMembers" /> Minds Mapping Brand
          Recall
          </span>
          
        </div>
        <div class="legal">
          <button @click="handleLegal" class="bottom-button">Legal</button>
        </div>
      </div>
    </footer>
  </div>
  <!-- <div
    v-if="showMobilePopup"
    class="mobile-popup-overlay"
    @click.self="closeMobilePopup"
  >
    <div class="mobile-popup-content">
      <p>
        This is a Beta Application! We recommend using a laptop or desktop for
        the best experience.
      </p>
      <button @click="closeMobilePopup">OK</button>
    </div>
  </div> -->
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Sidebar from "./Sidebar.vue";
import BrandGame from "../b2cgame/BrandGame.vue";
import BrandGameAgain from "../b2cgame/BrandGameAgain.vue";
import BrandGamePopup from "../b2cgame/BrandGamePopup.vue";
import ShareThoughtsPopup from "../b2cgame/ShareThoughtsPopup.vue";
import InstructionsPopup from "./InstructionsPopup.vue";
import WelcomePopup from "./WelcomePopup.vue";
import { mapGetters, mapActions } from "vuex";
import CountingNumber from "./CountingNumber.vue";
import B2CLoginPopup from "./B2CLoginPopup.vue";
import SearchLimitPopup from "./SearchLimitPopup.vue";
import B2CSignupPopup from "./B2CSignupPopup.vue";
import MindSearchPopup from "./MindSearchPopup.vue";
import WordPickerMobile from "@/components/target_pickers/ias/WordPickerMobile.vue";
import {
  alert_info,
  genericDialog,
  toast_success,
} from "@/helpers/alert_helper.js";

export default {
  name: "Mindspeller_Network",
  async created() {
    // 1) Debug: log the domain
    const domain = window.location.hostname;

    // 2) Check cookie override
    const override = this.getCookie("regionOverride");
    if (override) {
      this.currentlang = override;
    } else {
      await this.checkRegion();
    }

    // 3) Compare domain vs region
    // If user is on stg-cas-en but region is 'nl', redirect to stg-cas-nl
    // If user is on stg-cas-nl but region is 'en', redirect to stg-cas-en
    if (this.currentlang === "nl" && domain.includes("www.mindspeller.com")) {
      this.setCookie("regionOverride", 'nl', 30);
      window.location.href = "https://cas-nl.mindspeller.com/#/mindspeller_network";
    } else if (this.currentlang === "en" && domain.includes("cas-nl")) {
      this.setCookie("regionOverride", 'en', 30);
      window.location.href = "https://www.mindspeller.com/#/mindspeller_network";
    }

    // 4) If no mismatch => set the correct iframe
    this.setIframeUrl(this.currentlang);
    this.organizationName = this.currentlang;
    localStorage.setItem(
      "selectedNavigation",
      `CAS-${this.currentlang.toUpperCase()} Network`
    );
  },
  components: {
    Sidebar,
    InstructionsPopup,
    CountingNumber,
    BrandGame,
    BrandGameAgain,
    WelcomePopup,
    B2CLoginPopup,
    B2CSignupPopup,
    BrandGamePopup,
    ShareThoughtsPopup,
    SearchLimitPopup,
    WordPickerMobile,
    MindSearchPopup,
    organizationName: "",
    iframeSrc: '',
    currentlang: "",
  },
  computed: {
    ...mapGetters(["showInstructionPopup"]),

    mobileSearchBarClass() {
      return this.wordData && Object.keys(this.wordData).length
        ? "b2c-searchbar-wrapper-active"
        : "b2c-searchbar-wrapper-inactive";
    },
    isCasNl() {
      return window.location.hostname.includes("cas-nl");
    },
    translations() {
      const isCasNl = this.isCasNl;
      return {
        networkmainheader: isCasNl ? "Breaking Routine, Connecting Minds" : "Breaking Routine, Connecting Minds",
        networkheader1: isCasNl ? "Merkenkenner? " : "Think You Know Brands?",
        networkheader2: isCasNl ? "Daag Mindspeller uit!" : "Think Again!",
        earn_rewards: isCasNl ? "Verdien Beloningen" : "Earn Rewards",
        order_headset: isCasNl ? "Bestel Headset" : "Order Headset",
        read_me: isCasNl ? "Lees Mij" : "Read Me",
        my_search: isCasNl ? "Mijn Zoektocht" : "My Search",
        cant_find: isCasNl ? "Kunt u uw trefwoord niet vinden in de lijst?" : "Can't find your keyword in the list?",
        join_community: isCasNl ? "Word lid van onze community om het hier toe te voegen" : "Join our community to add it here",
        region: isCasNl ? "Regio:" : "Region:",
      };
    },
  },
  data() {
    return {
      index: null,
      wordData: null, // Stores word data received from API or mobile
      label: null,
      showBrandGame: false,
      showBrandGameAgain: false,
      brandGameStatus: false,
      surveyStatus: false,
      showSurveyPopup: false,
      showShareThoughts: false,
      totalAssociations: 117021,
      totalMembers: 117021,
      getLabelCallCount: 0,
      maxGetLabelCalls: 10,
      isGetLabelBlocked: false,
      showOverlay: false,
      showMobilePopup: false,
      mobileScreen: false, // Flag for mobile vs. desktop
      showMindSearchPopup: false,
      showJoinCommunity: false,
      currentlang: "",
      organizationName: "",
      api_call_count: 0,
      // iframeSrc: '',
    };
  },
  methods: {
    ...mapActions(["setSelectedNavigation"]),
    // async getGeolocation() {
    //   try {
    //     const response = await fetch("https://ipapi.co/json/");
    //     return response.json();
    //   } catch (error) {
    //     console.error("Error fetching geolocation:", error);
    //     return null;
    //   }
    // },
    async checkRegion() {
      // => Called if no override cookie is found
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        if (data && (data.country_code === "NL" || data.country_code === "BE")) {
          this.currentlang = "nl";
        } else {
          this.currentlang = "en";
        }
        // Save region in a cross-domain cookie for next time
        this.setCookie("regionOverride", this.currentlang, 30);
      } catch (error) {
        console.error("Error fetching geolocation:", error);
        this.currentlang = "en";
        this.setCookie("regionOverride", "en", 30);
      }
    },
    setIframeUrl(region) {
      // Single location for logic
      if (region === "nl") {
        this.iframeSrc = "https://tensorboardnl.mindspeller.com/#projector";
      } else {
        this.iframeSrc = "https://tensorboard.mindspeller.com/#projector";
      }
    },
    updateRegionOverride() {
      // => Called when user picks from <select>
      this.setCookie("regionOverride", this.currentlang, 30);
      // Now forcibly redirect to stg-cas-nl or stg-cas-en
      if (this.currentlang === "nl") {
        console.log("Manual override => redirecting to stg-cas-nl...");
        window.location.href = "https://cas-nl.mindspeller.com/#/mindspeller_network";
      } else {
        console.log("Manual override => redirecting to stg-cas-en...");
        window.location.href = "https://www.mindspeller.com/#/mindspeller_network";
      }
    },
    setCookie(name, value, days, domain = ".mindspeller.com") {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      let cookieStr = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
      cookieStr += ` domain=${domain};`;
      document.cookie = cookieStr;
      console.log("Set cookie:", cookieStr);
    },
    getCookie(name) {
      const cookieArr = document.cookie.split(";");
      for (let cookie of cookieArr) {
        let [key, value] = cookie.split("=");
        if (key.trim() === name) {
          return value;
        }
      }
      return null;
    },
    launchMindSearchPopup() {
      this.showMindSearchPopup = true;
    },
    closeMindSearchPopup() {
      this.showMindSearchPopup = false;
    },

    // ----- BRAND GAME LOGIC -----
    brandGameOverStatus() {
      this.brandGameStatus = true;
    },
    surveySubmitted() {
      this.surveyStatus = true;
    },
    openBrandGamePopup() {
      this.$refs.brandGamePopup.openPopup({
        brandGameStatus: this.brandGameStatus,
        surveyStatus: this.surveyStatus,
      });
    },
    launchBrandGame() {
      this.showBrandGame = true;
      this.showSurveyPopup = false;
    },
    closeBrandGame() {
      this.showBrandGame = false;
      this.openBrandGamePopup();
    },
    launchBrandGameAgain() {
      this.showBrandGameAgain = true;
    },
    closeBrandGameAgain() {
      this.showBrandGameAgain = false;
    },
    launchShareThoughts() {
      this.showSurveyPopup = true;
      this.showBrandGame = false;
    },
    closeShareThoughts() {
      this.showSurveyPopup = false;
      this.openBrandGamePopup();
    },
    joinCommunity() {
      this.showJoinCommunity = true;
    },
    // ----- POPUP / LOGIN -----
    closeMobilePopup() {
      this.showMobilePopup = false;
      document.body.style.overflow = "";
    },
    openWordPopup() {
      this.$refs.b2cLoginPopup.openPopup();
    },
    openLimitPopup() {
      this.$refs.searchLimitPopup.openPopup();
    },
    openSignupPopup() {
      this.$refs.b2cSignupPopup.openPopup();
    },
    openInstructionsPopup() {
      this.$refs.instructionsPopup.openPopup();
    },

    // ----- NAV / LEGAL / ADS -----
    handleAdvertising() {
      window.open("https://ads.mindspeller.com/", "_blank");
    },
    handleAboutUs() {
      window.open("https://ads.mindspeller.com/about-us/", "_blank");
    },
    handleLegal() {
      window.open("https://ads.mindspeller.com/legal/", "_blank");
    },
    openLoginPage() {
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("jwt_refresh_token");
      localStorage.removeItem("is_anonymous");
      localStorage.removeItem("pageText");
      localStorage.removeItem("pageName");
      this.$router.push({ name: "Login" });
    },

    // ----- IFRAME LOGIC (DESKTOP) -----
    handleIframeMessage(event) {
      if (event.data.type === "tensorboard-label") {
        const sidebar_selection = event.data.data;
        if (sidebar_selection) {
          this.label = sidebar_selection;
          this.get_label();
        } else {
          console.log("Sidebar selection is empty");
        }
      } else if (event.data.type === "clear-selection") {
        console.log("Clear selection received");
        this.wordData = null;
      }
    },
    async get_label() {
      // Usage limit logic
      const limit = localStorage.getItem("networkLimit");
      // if (limit == this.maxGetLabelCalls / 2 - 1) {
      //   this.openLimitPopup();
      // }
      // if (this.isGetLabelBlocked || limit >= this.maxGetLabelCalls) {
      //   this.openSignupPopup();
      //   return;
      // }

      this.getLabelCallCount++;
      localStorage.setItem("networkLimit", this.getLabelCallCount);
      this.usageLimit = localStorage.getItem("networkLimit");

      // if (this.getLabelCallCount >= this.maxGetLabelCalls) {
      //   this.isGetLabelBlocked = true;
      //   this.showOverlay = true;
      //   console.log("get_label calls are now blocked.");
      //   this.openSignupPopup();
      //   return;
      // }

      // Actual label fetch logic
      let payload = {
        label: this.label,
        organisation: this.organizationName,
      };
      try {
        const response = await axios.post(
          "/api/cas/survey_cleaning/projector",
          payload
        );
        let wordData = response.data;
        this.wordData = wordData;
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },

    resetOverlay() {
      this.getLabelCallCount = 0;
      this.isGetLabelBlocked = false;
      this.showOverlay = false;
    },

    // ----- MOBILE WORD PICKER -----
    onMobileWordSelected(originalFormat) {
      const limit = localStorage.getItem("networkLimit");
      // if (limit == this.maxGetLabelCalls / 2 - 1) {
      //   this.openLimitPopup();
      // }
      // if (this.isGetLabelBlocked || limit >= this.maxGetLabelCalls) {
      //   this.openWordPopup();
      //   return;
      // }

      this.getLabelCallCount++;
      localStorage.setItem("networkLimit", this.getLabelCallCount);
      this.usageLimit = localStorage.getItem("networkLimit");

      // if (this.getLabelCallCount >= this.maxGetLabelCalls) {
      //   this.isGetLabelBlocked = true;
      //   this.showOverlay = true;
      //   console.log("get_label calls are now blocked.");
      //   this.openWordPopup();
      //   return;
      // }
      this.showJoinCommunity = false;
      this.wordData = originalFormat;
    },
    onMobileWordRemoved() {
      this.wordData = null;
      this.showJoinCommunity = false;
      console.log("Mobile word removed.");
    },

    // ----- COUNTS -----
    async fetchCounts() {
      try {
        const response = await axios.get("/projector/taskbarcount");
        this.totalAssociations = response.data.total_word_associations;
        this.totalMembers = response.data.total_respondents;
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    },
    showAlert(message) {
      alert(message);
    },
  },
  watch: {
    wordData(newVal, oldVal) {
      // Trigger an alert only when we go from no data -> new data
      if (
        !oldVal &&
        newVal &&
        Object.keys(newVal).length &&
        !this.mobileScreen
      ) {
        alert_info(
          "Info",
          'Click "ISOLATE" to continue. Use the slider to limit the neighbors.',
          "justify"
        );
      }
    },
    currentlang(newVal) {
      this.setIframeUrl(newVal);
    },
    organizationName(newVal) {
      // Update iframeSrc if organizationName changes
      this.iframeSrc =
        this.currentlang === "en"
          ? "https://tensorboard.mindspeller.com/#projector"
          : "https://tensorboardnl.mindspeller.com/#projector";
    },
  },
  mounted() {
    // If mobile, we use WordPickerMobile
    if (window.innerWidth <= 1023) {
      this.mobileScreen = true;
      this.showMobilePopup = true;
    }

    // If not blocked, listen for iframe messages (desktop only or always?)
    window.addEventListener("message", this.handleIframeMessage);

    // Fetch initial counts
    this.fetchCounts();

    // Update counts periodically
    this.countUpdateInterval = setInterval(() => {
      this.fetchCounts();
    }, 100000); // 100 seconds

    // Check usage limit
    this.usageLimit = localStorage.getItem("networkLimit");
    const limit = localStorage.getItem("networkLimit");
    // if (limit >= this.maxGetLabelCalls) {
    //   this.openWordPopup();
    //   return;
    // }
  },
  beforeDestroy() {
    // Remove event listener
    window.removeEventListener("message", this.handleIframeMessage);
  },
};
</script>

<style>

.align{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}

.footer{
  position: fixed;
  bottom: 0px;
  background-color: #b5b6b7;
  align-items: center;
  z-index: 998;
}

.footer-inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-taskbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  min-height: 165px;
  max-height: 225px;
  background-color: white;
  z-index: 4; /* Highest z-index */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  /* border-bottom: 2px solid #6c63ff; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.logo-container {
  text-align: center; /* Center tagline below the logo */
  /* margin-right: 35%; */
}

.logo-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.mobile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  /* Let it scroll normally */
  position: relative; 
  z-index: 3;
  margin-top: 150px;
  margin-bottom: 150px;
  /* or just static; up to you */
}


.divider {
  border-right: 1px solid #000; /* Vertical line to separate logo and tagline */
  height: 60px; /* Adjust height as needed */
  margin: 0 20px; /* Spacing around the divider */
}
.tagline {
  white-space: nowrap;
  flex-grow: 1; /* Distributes available space evenly */
  text-align: center;
}

.tagline-for-header {
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.8em;
  display: inline-flex;
  align-items: center;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  z-index: 5;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  right: 5%;
  width: 550px;
  transform: translateX(15%); /* Moves tooltip slightly to the left */
  white-space: normal;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* Arrow pointing down */
.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Moves arrow above the box */
  right: 13%;
  transform: translateX(-50%);
  border-width: 0 8px 8px 8px; /* Creates an upward-facing triangle */
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent; /* Matches background */
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.left {
  text-align: right; /* Aligns left tagline to the right */
  font-weight: bold;
}

.right {
  text-align: left; /* Aligns right tagline to the left */
  font-weight: bold;
}

.top-taskbar-logo {
  max-width: 100%; /* Ensures the logo doesn't exceed its container width */
  height: auto; /* Maintains the aspect ratio */
  display: block; /* Ensures proper alignment */
  margin: 0 auto; /* Centers the logo horizontally */
}

.reward-icon-wrapper {
  /* position: absolute; */
  top: 20%; /* Adjust the distance from the top edge */
  left: 0%; /* Adjust distance from the right edge */
  border-radius: 50%; /* Make it circular */
  width: 300px; /* Circle size */
  /* height: 40px; Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-login-icon-wrapper {
  /* position: absolute; */
  top: 8%; /* Adjust the distance from the top edge */
  right: 3%; /* Adjust distance from the right edge */
  background-color: white; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  border: 1px solid black; /* Border around the circle */
  padding: 10px;
  width: 55px; /* Circle size */
  height: 55px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cta-icon-wrapper {
  /* position: absolute; */
  top: 15%; /* Adjust the distance from the top edge */
  right: 8%; /* Adjust distance from the right edge */
  border-radius: 50%; /* Make it circular */
  width: auto;
  max-width: 200px; /* Circle size */
  /* height: 40px; Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cta-icon-wrapper:hover {
  background-color: #f7f7f7;
  transform: scale(1.05);
}

.mindsearch-image {
  width: auto;
  max-width: 245px;
  margin: auto;
  margin-top: 15%;
}

.brand-icon-wrapper {
  position: absolute;
  top: 25%; /* Adjust the distance from the top edge */
  right: 10%; /* Adjust distance from the right edge */
  background-color: black; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.login-user-icon {
  color: black; /* Icon color */
  font-size: 20px; /* Adjust size as needed */
}

.cta-user-icon {
  color: black; /* Icon color */
  font-size: 10px; /* Adjust size as needed */
}

.login-text {
  position: absolute;
  bottom: -25px; /* Position the text below the user icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Dark background for the text */
  color: white; /* Text color */
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8em;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Ignore pointer events on the text */
  transition: opacity 0.3s ease; /* Smooth transition for appearing/disappearing */
}

.user-login-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}

.reward-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}

#second-iframe {
  width: 100%; /* Reduced width to 80% */
  height: 92%; /* Adjust height as needed */
  border: none;
  position: fixed; /* Fix the iframe to the viewport */
  bottom: 0;
  margin: 0 auto; /* Center horizontally */
  padding-top: 20px; /* Optional: spacing from the top */
}
.iframe-wrapper {
  position: fixed;
  top: 80px; /* match the fixed header height */
  bottom: 60px; /* match the bottom taskbar height */
  left: 0;
  right: 0;
  /* overflow: auto; */
}
.iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.b2c-sidebar-wrapper {
  position: relative;
  top: 30%;
  left: 0;
  /* width: 22%; */
  /* height: calc(100% - 60px); */
  z-index: 2;
}

.b2c-searchbar-wrapper-active {
  position: fixed;
  background-color: #fff;
  /* Adjust this value to match your top header height */
  top: 145px;
  left: 0;
  right: 0;
  /* width: 22%; */
  /* height: calc(100% - 60px); */
  z-index: 3;
}

.b2c-searchbar-wrapper-inactive {
  position: fixed;
  background-color: #fff;
  /* Adjust this value to match your top header height */
  top: 300px;
  left: 0;
  right: 0;
  /* width: 22%; */
  /* height: calc(100% - 60px); */
  z-index: 3;
}

/* Wrapper for Instructions Button and Label */
.b2c-instructions-wrapper {
  position: fixed;
  top: 35%; /* Adjust this for vertical alignment */
  left: 22%; /* Adjust this for horizontal alignment */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Space between the button and the label */
  z-index: 4;
}

.icon {
  font-size: 1.5em;
  color: white;
  pointer-events: none;
}

.instructions-button {
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.instructions-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.instructions-button:active {
  transform: scale(1);
}

.readme-label {
  display: inline-block !important;
  background-color: #7878e9;
  color: black;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* animation: bounce 1.5s infinite; */
  margin-left: 10px; /* Ensures spacing from the button */
  position: relative;
}

/* Pointing Arrow */
.readme-label::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%; /* Position arrow on the left side */
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #7878e9 transparent transparent;
}

/* Bottom Taskbar Styles */
.bottom-taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #b5b6b7; /* Light grey background */
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space items across the width */
  z-index: 5;
  /* border-top: 2px solid #6c63ff; */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow above the taskbar */
}

.bottom-taskbar-region {
  position: fixed;
  bottom: 5%;
  left: 0;
  width: 100%;
  background-color: #b5b6b7; /* Light grey background */
  padding: 5px 15px;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space items across the width */
  z-index: 5;
  /* border-top: 2px solid #6c63ff; */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow above the taskbar */
}

.bottom-taskbar-left,
.bottom-taskbar-right {
  display: flex;
  gap: 10px; /* Spacing between buttons */
}

.bottom-taskbar-center select {
  background: white;
  border-radius: 8px;
  border: none;
  font-size: 0.9em;
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 10px;
}

.bottom-taskbar-center select:hover {
  font-weight: bold;
}

.bottom-taskbar-center {
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  margin-right: 3%;
  color: #333;
  flex: 1; /* Allow the center to grow and push left/right sections to edges */
}

.bottom-button {
  background: none;
  border: none;
  font-size: 0.9em;
  /* font-weight: bold; */
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.bottom-button:hover {
  font-weight: bold;
}

.order-button {
  display: inline-block; /* Behave like a button */
  padding: 5px 10px; /* Padding for button-like appearance */
  background-color: #000; /* Button background color */
  color: white; /* Text color */
  font-weight: bold; /* Make text bold */
  font-size: 1.2em;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a 3D effect */
  cursor: pointer; /* Pointer cursor */
  transition: transform 0.2s; /* Smooth hover effect */
}

.order-button:hover {
  transform: scale(1.1); /* Enlarge slightly on hover */
}

.play-button {
  display: inline-block; /* Behave like a button */
  padding: 5px 10px; /* Padding for button-like appearance */
  background-color: #6c63ff; /* Button background color */
  color: white; /* Text color */
  font-weight: bold; /* Make text bold */
  font-size: 1.2em;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a 3D effect */
  cursor: pointer; /* Pointer cursor */
  animation: bounce 1.5s infinite; /* Flashing animation */
  transition: transform 0.2s; /* Smooth hover effect */
}

.play-button:hover {
  transform: scale(1.1); /* Enlarge slightly on hover */
}

.block-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 6; /* Ensure it is above all other elements */
  cursor: not-allowed; /* Change cursor to indicate blocking */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.underline-link {
  text-decoration: underline;
  font-size: large;
}

/* Updated Mobile Popup Overlay Styles */
.mobile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Darker overlay to emphasize the popup */
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Prevent scrolling on the overlay itself */
  overflow-y: hidden;
}

.mobile-popup-content {
  /* Make the popup feel more like a modal */
  background-color: #fff;
  width: 90%;
  max-width: 500px; /* Increase if you want a wider popup on mobile */
  padding: 30px 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.mobile-popup-content p {
  margin-bottom: 20px;
  font-size: 1.1rem; /* Increase text size for readability on mobile */
  font-weight: bold;
  line-height: 1.4;
}

.mobile-popup-content button {
  background-color: #6c63ff;
  border: none;
  color: #fff;
  font-size: 1rem;
  /* padding: 12px 20px; */
  width: 60px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.leftbar-title {
  font-family: "Roboto", sans-serif;
  font-size: 1.2em;
  color: #333;
  text-align: center;
  font-weight: bold;
  padding: 8px 10px;
  /* margin-top: 2px;  */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  z-index: 4;
}

.mobile-popup-content button:hover {
  opacity: 0.9;
}
.blink-text {
  animation: blink 2s infinite;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

@media screen and (min-width: 1600px) {
  .top-taskbar {
    min-height: 150px; /* Adjust height as needed */
  }
  .tagline-for-header {
    font-size: 1.8em;
  }
  .bottom-taskbar-region {
    bottom: 4%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .top-taskbar {
    min-height: 160px; /* Adjust height as needed */
  }
  .tagline-for-header {
    font-size: 1.5em;
  }
  .top-taskbar-logo {
    max-width: 350px; /* Restrict the maximum width on mobile */
    height: auto; /* Maintain aspect ratio */
  }
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  /* .top-taskbar {
    min-height: 160px;
  } */
  .tagline-for-header {
    font-size: 17px;
  }
    /* Logo Image */
    .top-taskbar-logo {
    max-width: 250px; /* Restrict the maximum width on mobile */
    height: auto; /* Maintain aspect ratio */
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  /* .top-taskbar {
    min-height: 160px;
  } */
  .tagline-for-header {
    font-size: 17px;
  }
    /* Logo Image */
    .top-taskbar-logo {
    max-width: 200px; /* Restrict the maximum width on mobile */
    height: auto; /* Maintain aspect ratio */
  }
}

@media only screen and (max-width: 768px) {
  /* Top Taskbar */
  .top-taskbar {
    min-height: 120px;
    max-height: 170px;
    padding: 5px;
    /* flex-direction: column; Stack items if necessary */
  }

  /* Logo Container */
  .logo-header-container {
    margin: 0 auto;
    text-align: center;
    /* flex-direction: column; */
  }
  .tagline-for-header {
    white-space: nowrap;
    font-weight: bold;
    font-size: 1em;
  }
  /* Divider */
  .divider {
    height: 40px; /* Reduced height */
    margin: 0 10px;
  }

  /* Taglines */
  .tagline {
    font-size: 0.9em;
    white-space: normal; /* Allow wrapping */
    text-align: end;
  }
  .left,
  .right {
    font-size: 0.9em;
  }

  /* Logo Image */
  .top-taskbar-logo {
    max-width: 150px; /* Restrict the maximum width on mobile */
    height: auto; /* Maintain aspect ratio */
  }

  /* Reward Icon */
  .reward-icon-wrapper {
    width: 50px; /* Smaller circle */
    height: 70px;
    margin-right: 5px !important;
    top: 25%; /* Adjust vertical position */
    left: 3%; /* Adjust horizontal position */
    font-size: 0.5em;
  }

  .tooltip-container .tooltip-text {
    font-size: 0.55em;
    width: 300px;
  }
  /* User Icon and Brand Icon */
  .user-login-icon-wrapper,
  .brand-icon-wrapper {
    width: 50px;
    height: 50px;
    top: 15%; /* Adjust vertical position */
    /* For brand icon adjust left/right if necessary */
  }
  .leftbar-title {
    font-size: 1em;
    padding: 6px 8px;
    margin-bottom: 8px;
  }

  .cta-icon-wrapper {
    width: 50px; /* Smaller circle */
    height: 70px;
    width: 50px;
    top: 25%; /* Adjust vertical position */
    right: 3%; /* Adjust horizontal position */
    font-size: 0.5em;
    animation: bounce 1.5s infinite;
  }
  .mindsearch-image {
    max-width: 120px;
    max-height: 120px;
  }

  .login-user-icon {
    font-size: 3em;
  }

  /* Login Text */
  .login-text {
    font-size: 1.5em;
    bottom: -20px;
  }

  /* iFrame */
  #second-iframe {
    height: 100%;
    /* padding-top: 10px; */
  }

  /* Sidebar */
  .b2c-sidebar-wrapper {
    /* position: fixed; */
    /* top: 40%; */
    left: 0;
    width: 100%;
    bottom: 20%;
    background-color: white;
    overflow-y: auto;
  }
  .b2c-searchbar-wrapper-active {
    /* top: 38%;
    right: 0;
    width: 80%;
    justify-content: center; */
    margin: 0 auto;
    background-color: white;
  }


  /* Instructions Wrapper */
  .b2c-instructions-wrapper {
    top: 40%;
    left: 15%;
    flex-direction: row;
    gap: 5px;
  }
  .icon {
    font-size: 1.2em;
  }
  .instructions-button {
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    font-size: 0.9em;
  }
  .readme-label {
    font-size: 0.8em;
    padding: 3px 7px;
    margin-left: 5px;
  }
  .readme-label::after {
    border-width: 4px;
  }

  /* Bottom Taskbar */
  .bottom-taskbar {
    flex-direction: column;
    padding: 5px 10px;
  }
  .bottom-taskbar-left,
  .bottom-taskbar-right {
    gap: 5px;
  }
  .bottom-taskbar-center {
    font-size: 0.8em;
    margin-right: 0;
    padding: 5px 0;
  }
  .bottom-button {
    font-size: 0.8em;
  }
  .play-button {
    font-size: 1.2em;
    padding: 4px 8px;
  }
  .order-button {
    font-size: 1.2em;
    padding: 4px 8px;
  }
  /* Block Overlay */
  .block-overlay {
    font-size: 1.2rem;
  }

  /* Underline Link */
  .underline-link {
    font-size: 1.2em;
  }

  /* Blink Text */
  .blink-text {
    font-size: 1.2em;
  }
  .align{
    gap: 12px;
  }
  .popup-footer{
    align-items: center;
    gap:4px;
  }
  .primary-button{
    padding: 4px;
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 480px) {
  .align{
    gap:13px !important
  }
  .popup-footer{
    flex-direction: column;
  }
  .primary-button{
    height: auto;
    width: 100%;
  }
  .footer-inner{
    /* flex-direction: column; */
    font-size: 10px;
  }
  .legal{
    display: none;
  }
  .b2c-searchbar-wrapper-inactive {
    top: 115px;
  }
  .leftbar-title {
    margin-bottom: 0px !important;
  }
  .top-taskbar-logo{
    max-width: 150px; /* Further restrict the size for very small screens */
    height: auto; /* Maintain aspect ratio */
  }
  .tagline-for-header{
    display: none;
  }
  .tagline {
    text-align: left;
  }
}
</style>
