<template>
  <div class="container-fluid full-width-pg p-0" id="mindspeller">
    <Header :showHeaderDropDown="showHeaderDropDown" v-if="pageReady && showHeader" />
    <!--  <router-view v-if="pageReady" />-->
    <router-view v-if="pageReady" v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <loading-app-wide v-if="!pageReady" :isInApp="false" />
    <!-- <div id="low-res-state">
      <p>
        Please view this application on a device that supports a bigger screen
      </p>
    </div> -->
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue';
import LoadingAppWide from '@/components/common/LoadingAppWide.vue';
import AxiosHelper from '@/helpers/axios_helper.js';
import { datadogRum } from '@datadog/browser-rum';
import { changeLanguage } from '@/helpers/language_helper.js';
// import markerSDK from '@marker.io/browser';
import axios from 'axios';
import { EventBus } from '@/eventBus.js';

export default {
  components: {
    Header,
    LoadingAppWide,
  },
  data: function () {
    return {
      pageReady: false,
      showHeader: true,
      showHeaderDropDown: true,
    };
  },
  // created() {
  //   EventBus.on('save-user-credentials', () => {
  //     const user = {
  //       email: this.$store.state.current_user.email,
  //       password: this.$store.state.current_user.password,
  //       username: this.$store.state.current_user.username,
  //     };
  //     console.log('app_user', user);
  //     $cookies.set('user', user);
  //   });
  // },
  beforeCreate: async function () {
    const axiosHelper = new AxiosHelper();
    try {
      // we refresh the token on every page load (we also set a timeout in axios
      // setup that will refresh the token every 14 min, since tokens expire after
      // 15 mins)
      await axiosHelper.updateToken();
      // after the token has been refreshed we can load the data about the user,
      // prices etc
      await this.$store.dispatch('initialize_page');
      // once that user data has been loaded we can set the app language based
      // on users preferred language which we recieved from the API
      changeLanguage(this.$i18n, this.$store.state.current_user.language);
    } catch {
    } finally {
      this.pageReady = true;
    }
  },
  mounted: async function () {
    // if (

    //   this.$store.getters
    //     .getEnvVar("VUE_APP_DEFAULT_LANGUAGE") === "en"
    // ) {
      // (async () => {
      //   try {
      //     const widget = await markerSDK.loadWidget({
      //       project: '67dac57ee83032ad7730809f'
      //     });
      //     widget.show();
      //     console.log("Marker.io widget loaded", widget);
      //   } catch (error) {
      //     console.error("Marker.io widget failed to load:", error);
      //   }
      // })();
    // }

    // CHATBASE CHATBOT IMPLEMENTATION
    (function(){
      if(!window.chatbase||window.chatbase("getState")!=="initialized"){
        window.chatbase=(...args)=>{
          if(!window.chatbase.q){
            window.chatbase.q=[];
          }
          window.chatbase.q.push(args);
        };
        window.chatbase=new Proxy(window.chatbase,{
          get(target,prop){
            if(prop==="q"){
              return target.q;
            }
            return(...args)=>target(prop,...args);
          }
        });
      }
      const onLoad=function(){
        const script=document.createElement("script");
        script.src="https://www.chatbase.co/embed.min.js";
        script.id="PnG-bYToKdZI25DJROrtA";
        script.domain="www.chatbase.co";
        document.body.appendChild(script);
      };
      if(document.readyState==="complete"){
        onLoad();
      } else {
        window.addEventListener("load",onLoad);
      }
    })();

    await this.$store.dispatch('loadAllEnvVars');
    if (
      this.$store.getters.getEnvVar('VUE_APP_DATADOG_APPLICATION_ID') !== ''
    ) {
      datadogRum.init({
        applicationId: this.$store.getters.getEnvVar(
          'VUE_APP_DATADOG_APPLICATION_ID'
        ),
        clientToken: this.$store.getters.getEnvVar(
          'VUE_APP_DATADOG_CLIENT_TOKEN'
        ),
        site: 'datadoghq.eu',
        service: this.$store.getters.getEnvVar('VUE_APP_DATADOG_SERVICE_NAME'),
        version: this.$store.getters.getEnvVar('VUE_APP_APP_VERSION'),
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'allow',
      });
      datadogRum.startSessionReplayRecording();
    }

    // add interceptors
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.message && (error.response.status == 503 ||error.response.status == 500)) {
          this.$router.push('/maintenance');
        }
        return Promise.reject(error)
      }
    )
  },
  watch: {
    $route(to, from) {
      // hide the header on the login page
      if (
        [
          'Free Trial',
          'RespondentSignup',
          'RespondentLogin',
          'Login',
          'Start Trial',
          'Onboarding',
          'Sign Up',
          'Password Setup',
          'Respondent Password Setup',
          'Forgot Password',
          'Maintenance',
          'Redirecting',
          'Mindspeller_Network',
        ].includes(to.name)
      ) {
        this.showHeader = false;
        this.showHeaderDropDown = false;
      } else {
        if (['Archetypes', 'Passions', 'Brand Value'].includes(to.name)) {
          this.showHeaderDropDown = false;
        } else {
          this.showHeaderDropDown = true;
        }
        this.showHeader = true;
      }
    },
  },
};
</script>

<style scoped>
#low-res-state {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  top: 0;
}

@media screen and (max-width: 1170px) {
  #low-res-state {
    display: flex;
  }
}

@media screen and (min-width: 1171px) {
  #low-res-state {
    display: none;
  }
}

p {
  margin-bottom: 10px;
  padding: 0 30px;
  font-size: 16px;
  line-height: 1.5;
}
.scroll-down-placeholder {
  height: 100px; 
}
</style>
