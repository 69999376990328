<template>
<div class="eeg-chart-container" :style="stateStyle">
    <h2>EEG Theta Signal</h2>
    <div class="state-display">
      <template v-if="learningStateValue">
        <font-awesome-icon icon="shield-alt" size="lg" class="shield-icon" />
        <span>{{ currentlang === 'nl' ? 'Beschermde status' : 'Shielded State' }}</span>
      </template>
      <template v-else>
        <span>{{ currentlang === 'nl' ? 'Exploratieve status' : 'Explorative State' }}</span>
      </template>
    </div>
  
    <!-- Manual toggle remains -->
    <div class="manual-toggle">
      <h4>{{ currentlang === 'nl' ? 'Theta-stand handmatig aan/uit' : 'Toggle Theta State Manually' }}</h4>
      <q-toggle v-model="learningStateValue" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: "EEGChart",
  components: { FontAwesomeIcon },
  setup() {
    const store = useStore();
    const currentlang = ref(store.getters.getEnvVar("VUE_APP_DEFAULT_LANGUAGE") || "nl");
    
    // Manage learning state (single source of truth)
    const learningStateValue = ref(store.state.learning_state);
    // Reflect manual changes in store.
    watch(learningStateValue, (newVal) => {
      store.commit("setLearningState", newVal);
    });
    
    // For debugging, still track the theta value from API
    const thetaValue = ref(0);
    
    // Fetch theta value from backend and update learningStateValue based on threshold.
    const fetchThetaValue = async () => {
      try {
        const response = await axios.get("/api/cas/eeg_state");
        // Expected response structure: { theta: <number> }
        thetaValue.value = response.data.theta;
        console.log("Theta value fetched:", thetaValue.value);
        // Automatically set learning state based on theta threshold.
        learningStateValue.value = thetaValue.value > 0.5;
      } catch (error) {
        console.error("Error fetching theta value:", error);
      }
    };
    
    // Poll the API every 10 seconds.
    let pollingInterval = null;
    onMounted(() => {
      fetchThetaValue();
      pollingInterval = setInterval(fetchThetaValue, 10000);
    });
    
    onBeforeUnmount(() => {
      if (pollingInterval) clearInterval(pollingInterval);
    });
    
    // Use learningStateValue to determine container style.
    const stateStyle = computed(() => {
      return {
        backgroundColor: learningStateValue.value ? "#4caf50" : "transparent",
        transition: "background-color 0.5s ease",
        padding: "10px",
        borderRadius: "8px"
      };
    });
    
    return { 
      currentlang,
      learningStateValue, 
      thetaValue, 
      stateStyle
    };
  }
};
</script>

<style scoped>
.eeg-chart-container {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  text-align: center;
}

.eeg-chart-container h2 {
  margin: 0 0 10px;
  font-size: 1em;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
}

.state-display {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
}

.shield-icon {
  color: #fff;
}

.manual-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.manual-toggle h4 {
  font-size: 0.9em;
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
}
</style>